.h-100 {
  height: 100%;
}
.p-12 {
  padding: 12px;
}
.text-center {
  text-align: center;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}

.flex-jcenter {
  justify-content: center;
}
.flex-acenter {
  align-items: center;
}
.flex-ajcenter {
  align-items: center;
  justify-content: center;
}
.white {
  color: white;
}
.MuiInput-root:focus {
  border: 1px solid #3075cb !important;
}
.Mui-disabled {
  color: white;
}
.Mui-focused {
  border: 1px solid #3075cb;
  caret-color: #3075cb;
  border-radius: 4px;
}
.m-child-8 > * {
  margin: 8px 0px !important;
}

.m-child-4 > * {
  margin: 4px 0px !important;
}

.w-100 {
  width: 100%;
}
.hidden {
  display: none;
}
.red {
  background-color: #8f2727;
}
.green {
  background-color: rgb(29, 98, 91);
}
.blue {
  background-color: #3075cb;
}
.spinner {
  animation: spin infinite 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
